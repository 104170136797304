import React, { Props } from 'react';
import './App.css';
import MediaPlayer from './component/MediaPlayer'
import TwitterSearchStream from './component/TwitterSearchStream'
import { Media } from './model/Media'
import Firebase from './api/Firebase'

interface State {
  currentMedia: Media
  playlist: Media[]
  // TBD: 再生終了してもプレイリストに残す？
  // currentIndex: number
  twitterId: string
  accessToken: string
  isSignedIn: boolean
}

class MediaHistorical {
  twitterId: string

  constructor(twitterId) {
    this.twitterId = twitterId
  }

  skip(media: Media): Promise<void> {
    if (media.platform === 'twitch') {
      return Promise.resolve()
    }
    return this.update(media, {
      is_skipped: true,
      skipped_at: Firebase.firestore.FieldValue.serverTimestamp(),
      watched_at: Firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  end(media: Media): Promise<void> {
    if (media.platform === 'twitch') {
      return Promise.resolve()
    }
    return this.update(media, {
      is_ended: true,
      ended_at: Firebase.firestore.FieldValue.serverTimestamp(),
      watched_at: Firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  like(media: Media): Promise<void> {
    return this.update(media, {
      is_liked: true,
      liked_at: Firebase.firestore.FieldValue.serverTimestamp(),
      watched_at: Firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  private update(media, data): Promise<void> {
    return Firebase.firestore()
      .collection("users")
      .doc(this.twitterId)
      .collection('medias')
      .doc(`${media.platform}-${media.id}`)
      .set(data, { merge: true })
  }

}

class App extends React.Component<{}, State> {
  state: State = {
    currentMedia: null,
    playlist: [],
    twitterId: null,
    accessToken: null,
    isSignedIn: false,
  }

  historical: MediaHistorical

  nextStyle = {
    padding: "20px",
    backgroundColor: "#333",
    top: "0",
    cursor: "pointer",
  }

  mediaPlayerStyle = {
    width: '100%',
  }

  constructor(props: Props<App>) {
    super(props);
    this.signIn = this.signIn.bind(this)
    this.onLikeClicked = this.onLikeClicked.bind(this)
    this.onNextClick = this.onNextClick.bind(this)
    this.onPlayerStateChanged = this.onPlayerStateChanged.bind(this)
    this.onSnapshot = this.onSnapshot.bind(this)
  }

  renderSignIn() {
    if (this.state.isSignedIn) {
      return null
    }
    return (
      <a href="#" onClick={this.signIn}>ログイン</a>
    )
  }

  render() {
    return (
      <div className="App">
        <header className="App-Header">
          {this.renderSignIn()}
          <TwitterSearchStream twitterId={this.state.twitterId} onSnapshot={this.onSnapshot}></TwitterSearchStream>
          <br />
          <div onClick={this.onLikeClicked}>お気に入り</div>
          <br />
          <br />
          <span style={this.nextStyle} onClick={this.onNextClick}>NEXT</span>
        </header>
        <MediaPlayer
          media={this.state.currentMedia}
          onStateChanged={this.onPlayerStateChanged}
        />
      </div >
    )
  }

  onSnapshot(medias: Media[]) {
    const playlist = this.state.playlist.concat(medias)
    if (this.state.currentMedia) {
      this.setState({
        playlist: playlist,
      })
    } else {
      this.setState({
        playlist: playlist,
        currentMedia: playlist[0],
      })
    }
  }

  async componentDidMount() {
    // TODO: クッキーに保存するまでの繋ぎ
    await Firebase.auth().signOut()

    Firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const twitterId = user.providerData[0].uid
        this.historical = new MediaHistorical(twitterId)
        this.setState({
          twitterId: twitterId,
          isSignedIn: true,
        })
      } else {
        this.historical = null
        this.setState({
          twitterId: null,
          isSignedIn: false,
        })
      }
    })
  }

  async signIn() {
    let provider = new Firebase.auth.TwitterAuthProvider();
    Firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        console.log('credential', credential)
        const twitterId = result.additionalUserInfo.profile['id']
        this.setState({
          twitterId: twitterId,
          accessToken: credential['accessToken'],
          isSignedIn: true,
        })
      }).catch((error) => {
        console.log(error)
      });
  }

  onLikeClicked() {
    this.historical.like(this.state.currentMedia)
  }

  onNextClick() {
    this.historical.skip(this.state.currentMedia)
    this.next()
  }

  next(callback = () => { }) {
    const newPlaylist = this.state.playlist.slice(1)
    this.setState({
      currentMedia: newPlaylist[0],
      playlist: newPlaylist,
    }, callback)
  }

  onPlayerStateChanged(event) {
    switch (event) {
      case "ready":
        break;
      case "ended":
        this.historical.end(this.state.currentMedia)
        this.next()
        break;
    }
  }
}

export default App
