import ReactYoutube, { Options } from 'react-youtube';
import { YouTubePlayer } from 'youtube-player/dist/types';
import { TwitchEmbed } from 'react-twitch-embed';
import { Media } from '../model/Media'

type Props = {
    media: Media,
    onStateChanged: (event) => void
}

let opts: Options = {
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        start: 0,
    },
}

const MediaPlayer = function (props: Props) {
    if (props.media === null) {
        return (
            <div></div>
        )
    }
    const onYouTubeReady = (event: { target: YouTubePlayer }) => {
        // setTimeout(() => {
        //     event.target.playVideo()
        // }, 2500)
        props.onStateChanged("ready")
    }
    const onYouTubeEnded = (event: { target: YouTubePlayer }) => {
        props.onStateChanged("ended")
    }
    const onTwitchEnded = () => {
        props.onStateChanged("ended")
    }

    switch (props.media.platform) {
        case 'soundcloud':
            // TODO: 再生終了をフックして次の動画に進む
            // 現状はNEXTを手動クリックして使う
            return (
                <iframe
                    id="soundcloud"
                    width="100%"
                    height="500px"
                    scrolling="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/816906499&amp;auto_play=true&amp;show_artwork=true&amp;visual=true&amp;origin=twitter">
                </iframe>
            )
        case "twitch":
            /* TODO: Clipやアーカイブに対応する
            <TwitchPlayer
              media="{this.state.currentVide.id}"
              onReady={this.onTwicthReady}
              onEnded={this.onTwitchEnded}
            /> */
            return (
                <TwitchEmbed
                    channel={props.media.id}
                    id={props.media.id}
                    theme="dark"
                    muted={false}
                />
            )
        case 'youtube':
            return (
                <ReactYoutube
                    videoId={props.media.id}
                    opts={opts}
                    onReady={onYouTubeReady}
                    onEnd={onYouTubeEnded}
                />
            )
        default:
            return (
                <div>
                    media { props.media.id}
                </div>
            )
    }
}

export default MediaPlayer;
