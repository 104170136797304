
export class Media {
  static fromDoc(doc): Media {
    let data = doc.data();
    switch (data.platform) {
      case 'soundcloud':
        return new Archive(
          data.platform,
          data.id,
          '',
          "title",
        )
      case 'twitch':
        return new Live(
          data.platform,
          data.id,
          '',
          "title",
        )
      case 'youtube':
        return new Archive(
          data.platform,
          data.id,
          '',
          "title",
        )
    }
  }

  platform: null | "soundcloud" | "youtube" | "twitch"
  publicid: string
  thumbnailUrl: URL
  title: string
  id: string
}

export class NonMedia implements Media {
  platform: null | "soundcloud" | "youtube" | "twitch";
  publicid: string;
  thumbnailUrl: URL;
  title: string;
  id: string;

  constructor(
    id = null,
    title = "",
    thumbnailUrl = null,
  ) {
    this.platform = null;
    this.id = id;
    this.thumbnailUrl = thumbnailUrl;
    this.title = title;
  }

}

export class Archive implements Media {
  platform: null | "soundcloud" | "youtube" | "twitch"
  publicid: string
  thumbnailUrl: URL
  title: string
  id: string
  constructor(
    platform = null,
    id = null,
    title = "",
    thumbnailUrl = null,
  ) {
    this.platform = platform
    this.id = id
    this.thumbnailUrl = thumbnailUrl
    this.title = title
  }
}

export class Live implements Media {
  platform: null | "soundcloud" | "youtube" | "twitch"
  publicid: string
  thumbnailUrl: URL
  title: string
  id: string
  constructor(
    platform = null,
    id = null,
    title = "",
    thumbnailUrl = null,
  ) {
    this.platform = platform
    this.id = id
    this.thumbnailUrl = thumbnailUrl
    this.title = title
  }
}
