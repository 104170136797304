import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAogaAg0eq1wZpi9ExZMfuMeQLZ2DiGySo",
    authDomain: "dougakun-e5742.firebaseapp.com",
    projectId: "dougakun-e5742",
    storageBucket: "dougakun-e5742.appspot.com",
    messagingSenderId: "447800058227",
    appId: "1:447800058227:web:2bb718592cc1646fcc6506",
    measurementId: "G-3NN0T3XXF8",
};

firebase.initializeApp(firebaseConfig);


// if (window.location.hostname === "localhost") {
//     firebase.firestore().useEmulator("localhost", 8080);
// }

// firebase.firestore().useEmulator("127.0.0.1", 8080);

export default firebase
