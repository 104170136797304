import Firebase from '../api/Firebase'
import { Media } from '../model/Media'

type Props = {
    twitterId: string,
    onSnapshot: (medias: Media[]) => void
}

const TwitterSearchStream = function (props: Props) {
    let buffer = []
    const onClick = () => {
        console.log('onClick')
        Firebase.firestore()
            .collection("users")
            .doc(`${props.twitterId}`)
            .collection('medias')
            .where('is_ended', '==', false)
            .where('is_skipped', '==', false)
            .where('is_fetched', '==', false)
            // TODO: MediaPlayerのバグで今はYouTubeのみ
            .where('platform', '==', 'youtube')
            .orderBy('created_at')
            .limit(100)
            .get()
            .then(querySnapshot => querySnapshot.docs.map(Media.fromDoc))
            .then(medias => {
                for (let i = 0; i < medias.length; i++) {
                    let media = medias[i]
                    let includedMedia = 0 < buffer.filter((m) => m.id === media.id).length
                    if (!includedMedia) {
                        buffer.push(media)
                    }
                }
                props.onSnapshot(medias)
            })
            .catch(e => console.log('ERROR', e))
    }
    return (
        <span onClick={onClick} id="TwitterSearchStream_Fire">リロード</span>
    )
}

export default TwitterSearchStream